import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next-src/src/components/common/scroll-to-top.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next-src/src/components/layout/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next-src/src/components/shared/NavigationBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/next-src/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/next-src/src/context/AppProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next-src/src/context/LocaleProvider.tsx");
